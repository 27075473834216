import './App.css';
import React from "react";
import Welcome from "./components/pages/welcome";

import Step0 from "./components/pages/Step0";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import NotFound from "./components/pages/Not Found/NotFound";

function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/form" component={Step0}/>
                    <Route exact path="/" component={Welcome}/>
                    <Route component={NotFound}/>
                    <Route path='/notFound' component={NotFound}/>
                </Switch>
            </Router>
        </>
    );
}

export default App;
