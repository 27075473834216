import React from 'react';
import Stepper from 'react-js-stepper'
import Aral from "../../assets/images-svg/Logo.png"
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {getData} from "../../assets/scripts/GeneralFunctions";
import {MDBFormInline, MDBInput} from "mdbreact";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import Loader from "./Loader/Loader";

const steps = [{title: 'مرحله اول'}, {title: 'مرحله دوم'}, {title: 'مرحله سوم'}, {title: 'مرحله چهارم'}, {title: 'مرحله پنجم'}, {title: 'مرحله ششم'}, {title: 'مرحله هفتم'}, {title: 'مرحله آخر'}];

export default class Step0 extends React.Component {
    constructor(props) {
        super(props);
        this.UploadFileInput = this.UploadFileInput.bind(this);
        this.state = {
            activeStep: 1,
            company_status: 'در حال توسعه',
            company_size: 'متوسط',
            radio3: '',
            radio4: '',
            activity_range: 'شهر',
            isUsersLoader: false,
            radio6: '',
            radio7: '',
            modal14: false,
            company_name: '',
            customer_name: '',
            mobile: localStorage.getItem('mobile'),
            instagram: '',
            facebook: '',
            twitter: '',
            linkedin: '',
            company_topic: 'شرکتی و سازمانی',
            activity_description: '',
            has_site: 'No',
            system_problems: '',
            isCheck1: false,
            isCheck2: false,
            isCheck3: false,
            isCheck4: false,
            isCheck5: false,
            isCheck6: false,
            isCheck7: false,
            isCheck8: false,
            isCheck9: false,
            isCheck10: false,
            is_check1_value: '',
            is_check2_value: '',
            is_check3_value: '',
            is_check4_value: '',
            is_check5_value: '',
            is_check6_value: '',
            is_check7_value: '',
            is_check8_value: '',
            is_check9_value: '',
            former_site: '',
            theme_type: 'هر دو حالت',
            site_language1: false,
            site_language2: false,
            site_language3: false,
            site_language4: false,
            site_language1_value: '',
            site_language2_value: '',
            site_language3_value: '',
            site_language4_value: '',
            has_logo: '0',
            upload_file: null,
            url_list: [],
            urls: '',
            url_description: '',
            show: false, platforms_topic: false, platforms_topic_value: '',
            platforms_topic1: false, platforms_topic1_value: '', platforms_topic2: false, platforms_topic2_value: '',
            platforms_topic3: false, platforms_topic3_value: '', selectedCheckboxes: ['تحت وب'],selectedProblem : [],site_language : []
        }

        this.parameters = {
            'company_status': this.state.company_status, 'company_size': this.state.company_size,
            'company_topic': this.state.company_topic, 'activity_range': this.state.activity_range,
            'project_theme': this.state.theme_type, 'order_design': this.state.has_logo,
            'implementation_platform': JSON.stringify(this.state.selectedCheckboxes)
        }

        this.problems = []
        this.web_site_language = []
        this.implementation_platform = []
        const formData = new FormData();
    }

    // Disable Click On Steps
    handleOnClickStepper = (step) => {
        if (this.state.activeStep == 7) {
            this.setState({activeStep: step});
        }
    };

    // Next Steps
    handleOnClickNext = () => {
        if (this.state.activeStep === 1) {
            let numRegex = /[0-9]{11}|[۰-۹]{11}/;
            if (this.state.company_name === '') {
                toast.error('فیلد نام شرکت الزامی است')
            } else if (this.state.customer_name === '') {
                toast.error('فیلد نام مشتری الزامی است')
            } else if (this.state.mobile === '') {
                toast.error('فیلد شماره تماس الزامی است')
            } else if (!numRegex.test(this.state.mobile)) {
                // console.log(this.state.phone_number)
                toast.error("شماره موبایل نامعتبر");
            } else {
                let nextStep = this.state.activeStep + 1;
                this.setState({activeStep: nextStep})
            }
        }
        else if (this.state.activeStep === 2) {
            let nextStep = this.state.activeStep + 1;
            this.setState({activeStep: nextStep})
        }
        else if (this.state.activeStep === 3) {
            if (this.state.selectedCheckboxes.length == 0) {
                toast.error('حداقل یکی از بسترهای پیاده سازی را انتخاب کنید')
            } else {
                let nextStep = this.state.activeStep + 1;
                this.setState({activeStep: nextStep})
            }
        }
        else if (this.state.activeStep === 4) {
            if (this.state.activity_description === '' && this.state.activity_description.length < 10) {
                toast.error('فیلد شرح فعالیت الزامی است')
            } else if (this.state.activity_description.length < 10) {
                toast.error('فیلد شرح فعالیت حداقل باید 10 کاراکتر باشد')
            } else if (this.state.has_site === 'No') {
                this.setState({activeStep: this.state.activeStep + 2})
            } else {
                let nextStep = this.state.activeStep + 1;
                this.setState({activeStep: nextStep})
            }
        }
        else if (this.state.activeStep === 6) {
            // console.log(this.web_site_language)
            if (this.state.site_language.length === 0) {
                toast.error('فیلد زبان سایت الزامی است')
            } else {
                let nextStep = this.state.activeStep + 1;
                this.setState({activeStep: nextStep})
            }
        }
        else {
            let nextStep = this.state.activeStep + 1;
            this.setState({activeStep: nextStep})
        }

    };

    // Prev Steps
    handleOnClickBack = () => {
        if (this.state.activeStep > 1) {
            let prevStep = this.state.activeStep - 1;
            this.setState({activeStep: prevStep})

        } else {
            return false
        }
        if (this.state.has_site === 'No') {
            if (this.state.activeStep === 6)
                this.setState({activeStep: this.state.activeStep - 2})
        }
    };


    handleChangeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.parameters = {...this.parameters, [e.target.name]: e.target.value}
    };


    companyStatus = (nr) => {
        this.setState({
            company_status: nr
        })
        this.parameters = {...this.parameters, 'company_status': nr}
    };
    companySize = nr => {
        this.setState({
            company_size: nr
        });
        this.parameters = {...this.parameters, 'company_size': nr}
    };

    /******* Step 2 ************/
    subjectActivity = e => {
        const {value} = e.target;
        this.setState({
            company_topic: value
        });
        this.parameters = {...this.parameters, 'company_topic': value}
    };

    /****** Step 3 ***********/
    subjectPlatforms = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes;
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
        }
        this.setState({
            selectedCheckboxes: selectedCheckboxes
        });
        this.parameters = {
            ...this.parameters, 'implementation_platform': JSON.stringify(selectedCheckboxes)
        }
    };

    activityRange = e => {
        const {value} = e.target;
        this.setState({
            activity_range: value
        });
        this.parameters = {...this.parameters, 'activity_range': value}
    };
    
    hasSite = e => {
        const {value} = e.target;
        this.setState({
            has_site: value
        });
    };

    /******* Step 5 ************/

    systemProblems = id => {
        const selectedCheckboxes = this.state.selectedProblem;
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
        }
        this.setState({
            selectedProblem: selectedCheckboxes
        });
        this.parameters = {
            ...this.parameters, 'problems': JSON.stringify(selectedCheckboxes)
        }
    };

    /******* Step 6 ************/
    hasLogo = e => {

        const {value} = e.target;

        this.setState({
            has_logo: value
        });
        this.parameters = {...this.parameters, 'order_design': value}
    };
    themeType = (e) => {
        if (e.target.value == 'تیره') {
            this.setState({dark_theme: 'dark_theme'})
        } else {
            this.setState({dark_theme: ''})
        }
        this.setState({
            theme_type: e.target.value
        })
        this.parameters = {...this.parameters, 'project_theme': e.target.value}
    };

    siteLanguage = id => {
        const selectedCheckboxes = this.state.site_language;
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
        }
        this.setState({
            site_language: selectedCheckboxes
        });
        this.parameters = {
            ...this.parameters, 'web_site_language': JSON.stringify(selectedCheckboxes)
        }
    };

    UploadFileInput(e) {

        //convert image to base64
        let imgFileReader = new FileReader();
        let currentClass = this;
        let image = new Image();

        imgFileReader.onload = function async(event) {

            image.onload = function () {
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.width = 450;
                canvas.height = 450 * image.height / image.width;
                context.drawImage(image,
                    0,
                    0,
                    image.width,
                    image.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );


                currentClass.setState(prevState => ({
                    ...prevState.state,
                    image: canvas.toDataURL()
                }));
                return canvas.toDataURL();
            };
            image.src = event.target.result;

        };

        imgFileReader.onloadend = function (e) {
            // console.log(imgFileReader.result)
            this.setState({
                image: imgFileReader.result
            }, () => {
                this.parameters = {...this.parameters, 'logo_image': image.src}
            })
        }.bind(this);


        let uploadImage = document.getElementById("upload_logo");

        if (uploadImage.files.length === 0) {
            return;
        }

        let uploadFile = document.getElementById("upload_logo").files[0];

        imgFileReader.readAsDataURL(uploadFile);

        let img = image

        this.setState({upload_flie: img.src})

        this.setState({
            file: URL.createObjectURL(e.target.files[0])
        })
    }

    /******* Step 7 ************/
    siteUrls = (e) => {
        this.setState({
            urls: e.target.value
        })
    };
    siteUrlsDescription = (e) => {
        this.setState({
            url_description: e.target.value
        })
    };
    saveUrls = () => {
        const urls = this.state.urls;
        const url_description = this.state.url_description;
        const obj = {'url': urls, 'description': url_description};

        const newArray = this.state.url_list.slice(); // Create a copy

        newArray.push(obj); // Push the object
        let newArr = [...this.state.url_list, obj]
        this.setState({
            url_list:newArr,
            urls: '',
            url_description: ''
        });
        this.parameters = {...this.parameters, 'desired_sites': JSON.stringify(newArr)}
    }
    removeUrl = (index) => {

        let array = [...this.state.url_list]; // make a separate copy of the array
        for (let i = 0; i < array.length; i++) {
            if (i === index) {
                array.splice(index, 1);
                this.setState({
                    url_list: array
                }, () => {
                    this.parameters = {...this.parameters, 'desired_sites': JSON.stringify(this.state.url_list)}
                })
            }

        }
    }
    handleShow = (index) => {
        const item = this.state.url_list.find((row, i) => (i === index))
        // console.log(item)
        this.setState({
            select_url: item.url,
            select_description: item.description,
            show: true,
            select_id: index
        })
    };
    handleClose = () => {
        this.setState({show: false})
    }
    editTextDescription = (e) => {
        // console.log(e.target.value)
        this.setState({
            select_description: e.target.value
        })
    };
    saveDescript = () => {
        const item = this.state.url_list.find((row, i) => (i === this.state.select_id))
        item.description = this.state.select_description

        this.setState({show: false , url_list : this.state.url_list})
        this.parameters = {...this.parameters, 'desired_sites': JSON.stringify(this.state.url_list)}
    };

    /******* Last Step ************/
    saveInformation = async () => {
        this.parameters = {...this.parameters, 'mobile': this.state.mobile}
        const {parameters} = this
        // console.log(parameters)
        this.setState({isUsersLoader: true})

        // console.log(parameters)

        let data = await getData(MAIN_URL, 'project-create', 'post', parameters, true, false);
        if (data) {
            // console.log(data)
            // window.location.reload();
            this.setState({isUsersLoader: false})
            Swal.fire({
                position: 'center-center',
                icon: 'success',
                title: 'اطلاعات شما با موفقیت ثبت شد',
                showConfirmButton: false,
                timer: 2500
            })
            setTimeout(
                () => {
                    window.location.replace('/')
                }, 2700
            )
        }
        // console.log(parameters)
    }

    render() {

        const {company_topic, selectedCheckboxes , selectedProblem , site_language , url_list} = this.state;
        const {parameters} = this

        return (
            <div className={this.state.dark_theme == 'dark_theme' ? 'dark_theme' : ''}>
                <div className={'container dv-custom-container'}>
                    <div className='row rtl'>
                        {
                            this.state.activeStep === 8 ?
                                <>
                                    <div className='container dv-size-last d-flex justify-content-center align-items-center'>
                                        <div className='row d-flex justify-content-center align-items-center'>
                                            <div className='col-12 d-flex justify-content-center mt-4 mb-5'>
                                                <Link to={'#'}><img src={Aral} className='img-fluid step-logo'
                                                                    alt='logo'/></Link>
                                            </div>
                                            <h5 className='mb-5 col-12 d-flex justify-content-center'>بازبینی نهایی فرم
                                                ارزیابی پروژه</h5>
                                            <div
                                                className='d-flex justify-content-center col-12 mt-4 mb-5 dv-border-stepper'>
                                                <Stepper
                                                    steps={steps}
                                                    activeStep={this.state.activeStep}
                                                    onSelect={this.handleOnClickStepper}
                                                    showNumber={true}
                                                />
                                            </div>
                                            <div className='col-12 d-block d-md-none'>
                                                {/*Next And Prev Button*/}
                                                <div
                                                    style={{marginTop: '1rem'}}
                                                    className='d-flex justify-content-center align-items-center mb-5 mb-md-2'>
                                                    <div
                                                        className={this.state.activeStep === steps.length - (steps.length - 1) ? 'dv-prev-btn-first d-flex justify-content-center align-items-center rounded-circle' : 'dv-prev-btn d-flex justify-content-center align-items-center rounded-circle'}
                                                        onClick={this.handleOnClickBack}>
                                                        <i className="fas fa-chevron-right"/>
                                                    </div>
                                                    <div className='d-flex align-items-center'><span
                                                        className='dv-number-of-step rounded-lg'>{steps.length}</span>/<span>{this.state.activeStep}</span>
                                                    </div>
                                                    <div
                                                        className={this.state.activeStep === steps.length ? 'dv-next-btn-last d-flex justify-content-center align-items-center rounded-circle' : 'dv-next-btn d-flex justify-content-center align-items-center rounded-circle'}
                                                        onClick={this.state.activeStep === steps.length ? null : this.handleOnClickNext}>
                                                        <i className="fas fa-chevron-left"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 d-flex justify-content-center mt-5'>
                                                <button type={"submit"} onClick={this.saveInformation}
                                                        className='dv-last-btn'>ثبت نهایی
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div
                                        className='col-12 d-flex justify-content-center align-items-center d-block col-md-2 mt-4'>
                                        <Link to={'#'}><img src={Aral} className='img-fluid step-logo'
                                                            alt='logo'/></Link>
                                    </div>
                                    <div className='d-none d-md-block col-md-10 mt-4'>
                                        <Stepper
                                            steps={steps}
                                            activeStep={this.state.activeStep}
                                            onSelect={this.handleOnClickStepper}
                                            showNumber={true}
                                        />
                                    </div>
                                    <div className='col-12 dv-custom-main-height'>
                                        <div style={{marginTop: '20px'}}>
                                            {/*Step 1*/}
                                            {this.state.activeStep === 1 ?
                                                <div className='mt-5'>
                                                    <form className='row'>
                                                        <div className='col-12'>
                                                            <h5 className='mb-4 mt-0 mt-sm-2'>اطلاعات عمومی</h5>
                                                        </div>
                                                        <div className='col-12 col-md-4 mb-5'>
                                                            <input type='text' placeholder='نام مجموعه'
                                                                   onChange={this.handleChangeInput}
                                                                   value={this.state.company_name}
                                                                   name={'company_name'}
                                                                   className='rj-form w-100' required/>
                                                        </div>
                                                        <div className='col-12 col-md-4 mb-5'>
                                                            <input onChange={this.handleChangeInput}
                                                                   name={'customer_name'}
                                                                   value={this.state.customer_name} type={'text'}
                                                                   placeholder="نام و نام خانوادگی"
                                                                   className='rj-form'/>
                                                        </div>
                                                        <div className='col-12 col-md-4 mb-5'>
                                                            <input onChange={this.handleChangeInput}
                                                                   name={'mobile'}
                                                                   value={this.state.mobile}
                                                                   placeholder="شماره تماس"
                                                                   type="number"
                                                                   className='rj-form'/>
                                                        </div>
                                                        <div className='col-12 col-md-6 mb-5'>
                                                            <h5 className='mb-4'>وضعیت مجموعه</h5>
                                                            <div className='d-flex justify-content-start'>
                                                                <label htmlFor="radio1"
                                                                       className={'dv_radio_button_label'}>
                                                                    <input
                                                                        onClick={() => this.companyStatus('تازه تاسیس')}
                                                                        checked={this.state.company_status === 'تازه تاسیس' ? true : false}
                                                                        type='radio'
                                                                        id='radio1'
                                                                    />
                                                                    <span>تازه تاسیس</span>
                                                                </label>
                                                                <label htmlFor="company_size"
                                                                       className={'dv_radio_button_label'}>
                                                                    <input
                                                                        onClick={() => this.companyStatus('در حال توسعه')}
                                                                        checked={this.state.company_status === 'در حال توسعه' ? true : false}
                                                                        type='radio'
                                                                        id='company_size'
                                                                    />
                                                                    <span>در حال توسعه</span>
                                                                </label>
                                                                <label htmlFor="radio3"
                                                                       className={'dv_radio_button_label'}>
                                                                    <input
                                                                        onClick={() => this.companyStatus('توسعه یافته')}
                                                                        checked={this.state.company_status === 'توسعه یافته' ? true : false}
                                                                        type='radio'
                                                                        id='radio3'
                                                                    />
                                                                    <span>توسعه یافته</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-6 mb-5'>
                                                            <h5 className='mb-4'>اندازه مجموعه</h5>
                                                            <div className='d-flex justify-content-start'>
                                                                <label htmlFor="radio12"
                                                                       className={'dv_radio_button_label'}>
                                                                    <input
                                                                        onClick={() => this.companySize('کوچک')}
                                                                        checked={this.state.company_size === 'کوچک' ? true : false}
                                                                        type='radio'
                                                                        id='radio12'
                                                                    />
                                                                    <span>کوچک</span>
                                                                </label>

                                                                <label htmlFor="company_size2"
                                                                       className={'dv_radio_button_label'}>
                                                                    <input
                                                                        onClick={() => this.companySize('متوسط')}
                                                                        checked={this.state.company_size === 'متوسط' ? true : false}
                                                                        type='radio'
                                                                        id='company_size2'
                                                                    />
                                                                    <span>متوسط</span>
                                                                </label>

                                                                <label htmlFor="radio32"
                                                                       className={'dv_radio_button_label'}>
                                                                    <input
                                                                        onClick={() => this.companySize('بزرگ')}
                                                                        checked={this.state.company_size === 'بزرگ' ? true : false}
                                                                        type='radio'
                                                                        id='radio32'
                                                                    />
                                                                    <span>بزرگ</span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-12 mb-3'>
                                                            <h5>شبکه های اجتماعی مجموعه خود را معرفی کنید</h5>
                                                        </div>

                                                        <div className='col-12 col-md-3 mb-4'>
                                                            <div className="input-group dv-input-group mb-4 mb-md-0">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text dv-input-group-text"
                                                                        id="basic-addon">
                                                                      <i className="fab fa-instagram"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control"
                                                                       onChange={this.handleChangeInput}
                                                                       value={this.state.instagram}
                                                                       name={'instagram'}
                                                                       placeholder="آی دی اینستاگرام"
                                                                       aria-label="Instagram"
                                                                       aria-describedby="basic-addon"/>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-4'>
                                                            <div className="input-group dv-input-group mb-4 mb-md-0">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text dv-input-group-text"
                                                                        id="basic-addon">
                                                                      <i className="fab fa-facebook-square"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control"
                                                                       onChange={this.handleChangeInput}
                                                                       name={'facebook'}
                                                                       value={this.state.facebook}
                                                                       placeholder="آی دی فیسبوک" aria-label="Facebook"
                                                                       aria-describedby="basic-addon"/>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-4'>
                                                            <div className="input-group dv-input-group mb-4 mb-md-0">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text dv-input-group-text"
                                                                        id="basic-addon">
                                                                      <i className="fab fa-twitter"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control"
                                                                       onChange={this.handleChangeInput}
                                                                       name={'twitter'}
                                                                       value={this.state.twitter}
                                                                       placeholder="آی دی توئیتر" aria-label="Twitter"
                                                                       aria-describedby="basic-addon"/>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-4'>
                                                            <div className="input-group dv-input-group mb-4 mb-md-0">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text dv-input-group-text"
                                                                        id="basic-addon">
                                                                      <i className="fab fa-linkedin"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control"
                                                                       onChange={this.handleChangeInput}
                                                                       name={'linkedin'}
                                                                       value={this.state.linkedin}
                                                                       placeholder="آی دی لینکدین" aria-label="Linkedin"
                                                                       aria-describedby="basic-addon"/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div> :
                                                // Step 2
                                                this.state.activeStep === 2 ?
                                                    <div className='row'>
                                                        <div className='col-12 mb-4 mt-0 mt-sm-2'>
                                                            <h5>موضوع فعالیت را انتخاب کنید</h5>
                                                        </div>
                                                        <div className='col-12 mb-4'>
                                                            <div className="custom-radios row">
                                                                <div className='col-6 col-md-2'>
                                                                    <input type="radio" className='step-2-input'
                                                                           checked={company_topic === 'شرکتی و سازمانی'}
                                                                           onChange={this.subjectActivity} id="Company"
                                                                           name="step_2_title" value="شرکتی و سازمانی"/>
                                                                    <label htmlFor="Company">
                                                                        <div
                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                            <i className="far fa-building mb-4 dv-icon-card"/>
                                                                            <h6>شرکتی و سازمانی</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                                <div className='col-6 col-md-2'>
                                                                    <input type="radio" className='step-2-input'
                                                                           checked={company_topic === 'فروش کالا و خدمات'}
                                                                           onChange={this.subjectActivity} id="Shop"
                                                                           name="step_2_title"
                                                                           value="فروش کالا و خدمات"/>
                                                                    <label htmlFor="Shop">
                                                                        <div
                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                            <i className="far fa-shopping-cart mb-4 dv-icon-card"/>
                                                                            <h6>فروش کالا و خدمات</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                                <div className='col-6 col-md-2'>
                                                                    <input type="radio" className='step-2-input'
                                                                           checked={company_topic === 'محتوا محور'}
                                                                           onChange={this.subjectActivity} id="Content"
                                                                           name="step_2_title" value="محتوا محور"/>
                                                                    <label htmlFor="Content">
                                                                        <div
                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                            <i className="far fa-pen-square mb-4 dv-icon-card"/>
                                                                            <h6>محتوا محور</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                                <div className='col-6 col-md-2'>
                                                                    <input type="radio" className='step-2-input'
                                                                           checked={company_topic === 'استارت آپ'}
                                                                           onChange={this.subjectActivity} id="Startup"
                                                                           name="step_2_title" value="استارت آپ"/>
                                                                    <label htmlFor="Startup">
                                                                        <div
                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                            <i className="fal fa-rocket mb-4 dv-icon-card"/>
                                                                            <h6>استارت آپ</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                                <div className='col-6 col-md-2'>
                                                                    <input type="radio" className='step-2-input'
                                                                           checked={company_topic === 'نرم افزار تحت وب'}
                                                                           onChange={this.subjectActivity}
                                                                           id="Web-applications" name="step_2_title"
                                                                           value="نرم افزار تحت وب"/>
                                                                    <label htmlFor="Web-applications">
                                                                        <div
                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                            <i className="fal fa-window-maximize mb-4 dv-icon-card"/>
                                                                            <h6>نرم افزار های تحت وب</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                                <div className='col-6 col-md-2'>
                                                                    <input type="radio" className='step-2-input'
                                                                           checked={company_topic === 'سایر'}
                                                                           onChange={this.subjectActivity} id="Other"
                                                                           name="step_2_title" value="سایر"/>
                                                                    <label htmlFor="Other">
                                                                        <div
                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                            <i className="fas fa-ellipsis-h mb-4 dv-icon-card"/>
                                                                            <h6>سایر</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {
                                                            company_topic === 'شرکتی و سازمانی' ?
                                                                <>
                                                                    <div className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                        <h5 className="font-weight-bold">شرکتی و
                                                                            سازمانی</h5>
                                                                    </div>
                                                                    <p>
                                                                        سایت شرکتی به صورت اختصاصی برای معرفی محصولات و
                                                                        خدمات شرکت ها و سازمان ها استفاده می شود که در
                                                                        آن امکاناتی از قبیل صفحه معرفی شرکت ، معرفی
                                                                        محصولات، گالری تصاویر، صفحه تماس و … وجود دارد.
                                                                    </p>
                                                                </>
                                                                :
                                                                company_topic === 'فروش کالا و خدمات' ?
                                                                    <>
                                                                        <div className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                            <h5 className="font-weight-bold">فروش کالا و
                                                                                خدمات</h5>
                                                                        </div>
                                                                        <p>
                                                                            وبسایتهایی که اقدام به فروش آنلاین محصولات و
                                                                            خدمات میکنند را وبسایت فروشگاهی می نامند. در
                                                                            طراحی سایتهای فروشگاهی امکاناتی نظیر سبد
                                                                            خرید، جستجوی پیشرفته کالا ها، سیستم فروش فوق
                                                                            العاده،سیستم کوپن و تخفیف و …درنظر گرفته
                                                                            میشود.
                                                                        </p>
                                                                    </>
                                                                    :
                                                                    company_topic === 'محتوا محور' ?
                                                                        <>
                                                                            <div className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                                <h5 className="font-weight-bold">محتوا
                                                                                    محور</h5>
                                                                            </div>
                                                                            <p>
                                                                                وب سایت های خبری، بلاگ ها و مجله های
                                                                                اینترنتی که به صورت محوری روی محتوای
                                                                                متنی، تصویری، ویدئویی و صوتی تمرکز
                                                                                میکنند را شامل می شود.
                                                                            </p>
                                                                        </>
                                                                        :
                                                                        company_topic === 'استارت آپ' ?
                                                                            <>
                                                                                <div
                                                                                    className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                                    <h5 className="font-weight-bold">استارت
                                                                                        آپ</h5>
                                                                                </div>
                                                                                <p>
                                                                                    استارت آپ های خلاقانه همان ایده های
                                                                                    نو هستند، ایده هایی که می توانند
                                                                                    آینده شما را زیر و رو کنند. اما برای
                                                                                    اجرای این ایده های خلاقانه به یک
                                                                                    مجری و راهنمای مناسب نیاز دارید. این
                                                                                    سرویس می تواند راهنمای مطمئنی برای
                                                                                    شما در اجرای این ایده های خلاقانه
                                                                                    باشد.
                                                                                </p>
                                                                            </>
                                                                            :
                                                                            company_topic === 'نرم افزار تحت وب' ?
                                                                                <>
                                                                                    <div
                                                                                        className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                                        <h5 className="font-weight-bold">نرم
                                                                                            افزار های تحت وب</h5>
                                                                                    </div>
                                                                                    <p>
                                                                                        نرم افزار های تحت وب و اتوماسیون
                                                                                        های اداری می توانند بستری مناسب
                                                                                        برای انجام امور ادارات، سازمان
                                                                                        ها، شرکت ها و... باشد. امکانات
                                                                                        اختصاصی و نیازسنجی شده از جمله
                                                                                        خصوصیات این سرویس می باشد.
                                                                                    </p>
                                                                                </>
                                                                                :
                                                                                // company_topic === 'سایر' ?
                                                                                //     <>
                                                                                //         <div
                                                                                //             className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                                //             <h5 className="font-weight-bold">سایر</h5>
                                                                                //         </div>
                                                                                //         <p>
                                                                                //             لورم ایپسوم متن ساختگی با تولید
                                                                                //             سادگی نامفهوم از صنعت چاپ و با
                                                                                //             استفاده از طراحان گرافیک است.
                                                                                //             چاپگرها و متون بلکه روزنامه و
                                                                                //             مجله در ستون و سطرآنچنان که لازم
                                                                                //             است و برای شرایط فعلی تکنولوژی
                                                                                //             مورد نیاز و کاربردهای متنوع با
                                                                                //             هدف بهبود ابزارهای کاربردی می
                                                                                //             باشد. کتابهای زیادی در شصت و سه
                                                                                //             درصد گذشته، حال و آینده شناخت
                                                                                //             فراوان جامعه و متخصصان را می
                                                                                //             طلبد تا با نرم افزارها شناخت
                                                                                //             بیشتری را برای طراحان رایانه ای
                                                                                //             علی الخصوص طراحان خلاقی و فرهنگ
                                                                                //             پیشرو در زبان فارسی ایجاد کرد.
                                                                                //             در این صورت می توان امید داشت که
                                                                                //             تمام و دشواری موجود در ارائه
                                                                                //             راهکارها و شرایط سخت تایپ به
                                                                                //             پایان رسد وزمان مورد نیاز شامل
                                                                                //             حروفچینی دستاوردهای اصلی و
                                                                                //             جوابگوی سوالات پیوسته اهل دنیای
                                                                                //             موجود طراحی اساسا مورد استفاده
                                                                                //             قرار گیرد.
                                                                                //         </p>
                                                                                //     </>
                                                                                //     :
                                                                                ''
                                                        }
                                                    </div> :
                                                    //     // Step 3
                                                    this.state.activeStep === 3 ?
                                                        <div className='row'>
                                                            <div className='col-12 mb-5 mt-0 mt-sm-2'>
                                                                <h5>بسترهای پیاده سازی خود را انتخاب کنید</h5>
                                                            </div>
                                                            <div className='col-12 mb-4'>
                                                                <div className="custom-radios row align-items-center">

                                                                    <div className='col-6 col-md-2'>
                                                                        <input type="checkbox" className='step-2-input'
                                                                               name={'تحت وب'}
                                                                               onChange={() => this.subjectPlatforms('تحت وب')}
                                                                               selected={selectedCheckboxes.includes('تحت وب')}
                                                                               id="web"/>
                                                                        <label htmlFor="web">
                                                                            <div
                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                <i className="fas fa-window-maximize mb-4 dv-icon-card"/>
                                                                                <h5>تحت وب</h5>
                                                                            </div>
                                                                        </label>
                                                                    </div>

                                                                    <div className='col-6 col-md-2'>
                                                                        <input type="checkbox" className='step-2-input'
                                                                               name={'اپلیکیشن اندروید'}
                                                                               onChange={() => this.subjectPlatforms('اپلیکیشن اندروید')}
                                                                               selected={selectedCheckboxes.includes('اپلیکیشن اندروید')}
                                                                               id="android_app"
                                                                        />
                                                                        <label htmlFor="android_app">
                                                                            <div
                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                <i className="fab fa-android mb-4 dv-icon-card"/>
                                                                                <h5>اپلیکیشن اندروید</h5>
                                                                            </div>
                                                                        </label>
                                                                    </div>

                                                                    <div className='col-6 col-md-2'>
                                                                        <input type="checkbox" className='step-2-input'
                                                                               name={'اپلیکیشن آی او اس'}
                                                                               onChange={() => this.subjectPlatforms('اپلیکیشن آی او اس')}
                                                                               selected={selectedCheckboxes.includes('اپلیکیشن آی او اس')}
                                                                               id="ios_app"
                                                                        />
                                                                        <label htmlFor="ios_app">
                                                                            <div
                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                {/*<i className="far fa-pen-square mb-4 dv-icon-card"/>*/}
                                                                                <i className="fab fa-apple mb-4 dv-icon-card"/>
                                                                                <h5>اپلیکیشن IOS</h5>
                                                                            </div>
                                                                        </label>
                                                                    </div>

                                                                    <div className='col-6 col-md-2'>
                                                                        <input type="checkbox" className='step-2-input'
                                                                               name={'اپلیکیشن ویندوز'}
                                                                               onChange={() => this.subjectPlatforms('اپلیکیشن ویندوز')}
                                                                               selected={selectedCheckboxes.includes('اپلیکیشن ویندوز')}
                                                                               id="windows_app"
                                                                        />
                                                                        <label htmlFor="windows_app">
                                                                            <div
                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                <i className="fab fa-windows  mb-4 dv-icon-card"></i>
                                                                                <h5>اپلیکیشن ویندوز</h5>
                                                                            </div>
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div> :
                                                        //         // Step 4
                                                        this.state.activeStep === 4 ?
                                                            <div className='row'>
                                                                <div className='col-12 mb-4 mt-0 mt-sm-2'>
                                                                    <h5>شرحی از فعالیت خود برای ما بنویسید:</h5>
                                                                </div>
                                                                <div className='col-12 mb-4 dv-textarea-parent '>
                                                                    <textarea className='dv-textarea p-3'
                                                                              onChange={this.handleChangeInput}
                                                                              name={'activity_description'}
                                                                              value={this.state.activity_description}
                                                                              rows={5}
                                                                              placeholder="افزودن اطلاعات"/>
                                                                </div>
                                                                <div className='col-12 col-md-8 mb-4'>
                                                                    <h5 className='mb-4'>محدوده فعالیت مجموعه</h5>
                                                                    <div className='col-12 mb-4 dv-radio-parent'>

                                                                        <div className='d-flex justify-content-start'>
                                                                            <label htmlFor="City" className={'dv_radio_button_label'}>
                                                                                <input
                                                                                    onClick={this.activityRange}
                                                                                    checked={this.state.activity_range === 'شهر'}
                                                                                    type='radio'
                                                                                    value={'شهر'}
                                                                                    name={'activity_range'}
                                                                                    id='City'
                                                                                />
                                                                                <span>شهر</span>
                                                                            </label>
                                                                            <label htmlFor="State"
                                                                                   className={'dv_radio_button_label'}>
                                                                                <input
                                                                                    onClick={this.activityRange}
                                                                                    checked={this.state.activity_range === 'استان'}
                                                                                    value={'استان'}
                                                                                    type='radio'
                                                                                    name={'activity_range'}
                                                                                    id='State'
                                                                                />
                                                                                <span>استان</span>
                                                                            </label>
                                                                            <label htmlFor="Country"
                                                                                   className={'dv_radio_button_label'}>
                                                                                <input
                                                                                    onClick={this.activityRange}
                                                                                    checked={this.state.activity_range === 'کشور'}
                                                                                    value={'کشور'}
                                                                                    type='radio'
                                                                                    name={'activity_range'}
                                                                                    id='Country'
                                                                                />
                                                                                <span>کشور</span>
                                                                            </label>
                                                                            <label htmlFor="international"
                                                                                   className={'dv_radio_button_label'}>
                                                                                <input
                                                                                    onClick={this.activityRange}
                                                                                    checked={this.state.activity_range === 'بین المللی'}
                                                                                    value={'بین المللی'}
                                                                                    type='radio'
                                                                                    name={'activity_range'}
                                                                                    id='international'
                                                                                />
                                                                                <span>بین المللی</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 col-md-4 mb-4'>
                                                                    <h5 className='mb-4'>آیا در حال حاضر وب سایت
                                                                        دارید؟</h5>
                                                                    <div className='col-12 d-flex'>
                                                                        <input type="radio"
                                                                               className='step-2-input step-2-input-bool'
                                                                               checked={this.state.has_site === 'Yes'}
                                                                               onChange={this.hasSite}
                                                                               id="Yes"
                                                                               name={this.state.has_site}
                                                                               value="Yes"/>
                                                                        <label htmlFor="Yes">
                                                                            <div
                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                <h5 className='mb-0'>بله</h5>
                                                                            </div>
                                                                        </label>
                                                                        <input type="radio"
                                                                               className='step-2-input step-2-input-bool'
                                                                               checked={this.state.has_site === 'No'}
                                                                               onChange={this.hasSite} id="No"
                                                                               name={this.state.has_site} value="No"/>
                                                                        <label htmlFor="No">
                                                                            <div
                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                <h5 className='mb-0'>خیر</h5>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            //             // Step 5
                                                            this.state.activeStep === 5 ?
                                                                <div className='row'>
                                                                    <div className='col-12 mb-4 col-md-6 mt-0 mt-sm-2'>
                                                                        <h5 className='mb-4'>آدرس سایت سابق خود را وارد
                                                                            کنید</h5>
                                                                        <input placeholder="آدرس سایت" type={'text'}
                                                                               className='rj-form pt-3 pb-3 ltr'
                                                                               value={this.state.former_site}
                                                                               name={'former_site'}
                                                                               onChange={this.handleChangeInput}
                                                                               size="lg"/>
                                                                    </div>
                                                                    <div className='col-12 mb-5 '>
                                                                        <h5 className='mb-5'>مشکلات سیستم سابق خود را
                                                                            انتخاب
                                                                            کنید.</h5>
                                                                        <div className='row'>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>قدیمی
                                                                                            شدن و آپدیت نبود ظاهر
                                                                                            سیستم</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'قدیمی شدن و آپدیت نبودن ظاهر سیستم'}
                                                                                                   onChange={() => this.systemProblems('قدیمی شدن و آپدیت نبودن ظاهر سیستم')}
                                                                                                   selected={selectedProblem.includes('قدیمی شدن و آپدیت نبودن ظاهر سیستم')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>محدودیت
                                                                                            در امکانات</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'محدودیت در امکانات'}
                                                                                                   onChange={() => this.systemProblems('محدودیت در امکانات')}
                                                                                                   selected={selectedProblem.includes('محدودیت در امکانات')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>پشتیبانی
                                                                                            ضعیف</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'پشتیبانی ضعیف'}
                                                                                                   onChange={() => this.systemProblems('پشتیبانی ضعیف')}
                                                                                                   selected={selectedProblem.includes('پشتیبانی ضعیف')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>مشکلات
                                                                                            و ایراد های ظاهری</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'مشکلات و ایرادهای ظاهری'}
                                                                                                   onChange={() => this.systemProblems('مشکلات و ایرادهای ظاهری')}
                                                                                                   selected={selectedProblem.includes('مشکلات و ایرادهای ظاهری')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>ضعف
                                                                                            های امنیتی</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'ضعف های امنیتی'}
                                                                                                   onChange={() => this.systemProblems('ضعف های امنیتی')}
                                                                                                   selected={selectedProblem.includes('ضعف های امنیتی')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>مشکلات
                                                                                            و ایراد های فنی</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'مشکلات و ایراد های فنی'}
                                                                                                   onChange={() => this.systemProblems('مشکلات و ایراد های فنی')}
                                                                                                   selected={selectedProblem.includes('مشکلات و ایراد های فنی')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>مشکلات
                                                                                            در بازاریابی</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'مشکلات در بازاریابی'}
                                                                                                   onChange={() => this.systemProblems('مشکلات در بازاریابی')}
                                                                                                   selected={selectedProblem.includes('مشکلات در بازاریابی')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>مشکلات
                                                                                            در سرعت و پایداری</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'مشکلات در سرعت و پایداری'}
                                                                                                   onChange={() => this.systemProblems('مشکلات در سرعت و پایداری')}
                                                                                                   selected={selectedProblem.includes('مشکلات در سرعت و پایداری')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-4 mb-3'>
                                                                                <div
                                                                                    className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                    <label
                                                                                        className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                        <h6 className='mb-0 pr-5 w-100 text-center'>عدم
                                                                                            تطبیق با دستگاه های
                                                                                            مختلف</h6>
                                                                                        <div>
                                                                                            <input type="checkbox"
                                                                                                   name={'عدم تطبیق با دستگاه های مختلف'}
                                                                                                   onChange={() => this.systemProblems('عدم تطبیق با دستگاه های مختلف')}
                                                                                                   selected={selectedProblem.includes('عدم تطبیق با دستگاه های مختلف')}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                this.state.activeStep === 6 ?
                                                                    <div className='row'>
                                                                        <div
                                                                            className='col-12 col-md-7 mb-5 mt-0 mt-sm-2'>
                                                                            <h5 className='mb-4'>تم رنگی پروژه</h5>
                                                                            <div className='row mb-5'>
                                                                                <div
                                                                                    className='col-6 col-md-3 dv-step5-radio mb-3'>
                                                                                    <input type="radio"
                                                                                           className='step-2-input'
                                                                                           checked={this.state.theme_type === 'روشن'}
                                                                                           onChange={this.themeType}
                                                                                           id="Light"
                                                                                           name="step_2_title"
                                                                                           value="روشن"/>
                                                                                    <label htmlFor="Light">
                                                                                        <div
                                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                            <i className="far fa-sun mb-4 dv-icon-card"/>
                                                                                            <h5>روشن</h5>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                                <div
                                                                                    className='col-6 col-md-3 dv-step5-radio mb-3'>
                                                                                    <input type="radio"
                                                                                           className='step-2-input'
                                                                                           checked={this.state.theme_type === 'تیره'}
                                                                                           onChange={this.themeType}
                                                                                           id="Dark"
                                                                                           name="step_2_title"
                                                                                           value="تیره"/>
                                                                                    <label htmlFor="Dark">
                                                                                        <div
                                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                            <i className="far fa-moon mb-4 dv-icon-card"/>
                                                                                            <h5>تیره</h5>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                                <div
                                                                                    className='col-6 col-md-3 dv-step5-radio mb-3'>
                                                                                    <input type="radio"
                                                                                           className='step-2-input'
                                                                                           checked={this.state.theme_type === 'هر دو حالت'}
                                                                                           onChange={this.themeType}
                                                                                           id="Both-modes"
                                                                                           name="step_2_title"
                                                                                           value="هر دو حالت"/>
                                                                                    <label htmlFor="Both-modes">
                                                                                        <div
                                                                                            className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                            <i className="fas fa-toggle-on mb-4 dv-icon-card"/>
                                                                                            <h5>هر دو حالت</h5>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 mb-md-4'>
                                                                                <h5 className='mb-4'>زبان وب سایت (امکان
                                                                                    انتخاب
                                                                                    بیش از یک مورد)</h5>
                                                                                <div className='row'>
                                                                                    <div
                                                                                        className='col-6 col-md-3 mb-3'>
                                                                                        <div
                                                                                            className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                            <label
                                                                                                className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                                <h6 className='mb-0 pr-5 w-100 text-center'>فارسی</h6>
                                                                                                <div>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name={'فارسی'}
                                                                                                        onChange={() => this.siteLanguage('فارسی')}
                                                                                                        selected={site_language.includes('فارسی')}
                                                                                                    />
                                                                                                    <span
                                                                                                        className="checkmark"></span>
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className='col-6 col-md-3 mb-3'>
                                                                                        <div
                                                                                            className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                            <label
                                                                                                className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                                <h6 className='mb-0 pr-5 w-100 text-start'>انگلیسی</h6>
                                                                                                <div>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name={'انگلیسی'}
                                                                                                        onChange={() => this.siteLanguage('انگلیسی')}
                                                                                                        selected={site_language.includes('انگلیسی')}
                                                                                                    />
                                                                                                    <span
                                                                                                        className="checkmark"></span>
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className='col-6 col-md-3 mb-3'>
                                                                                        <div
                                                                                            className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                            <label
                                                                                                className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                                <h6 className='mb-0 pr-5 w-100 text-center'>عربی</h6>
                                                                                                <div>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name={'عربی'}
                                                                                                        onChange={() => this.siteLanguage('عربی')}
                                                                                                        selected={site_language.includes('عربی')}
                                                                                                    />
                                                                                                    <span
                                                                                                        className="checkmark"></span>
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className='col-6 col-md-3 mb-3'>
                                                                                        <div
                                                                                            className="custom-control custom-checkbox custom-control-inline dv-parent-label-input position-relative">
                                                                                            <label
                                                                                                className="dv-checkbox-step4 d-flex w-100 justify-content-between align-items-center pl-4 rtl pt-3 pb-3">
                                                                                                <h6 className='mb-0 pr-5 w-100 text-center'>سایر</h6>
                                                                                                <div>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name={'سایر'}
                                                                                                        onChange={() => this.siteLanguage('سایر')}
                                                                                                        selected={site_language.includes('سایر')}
                                                                                                    />
                                                                                                    <span
                                                                                                        className="checkmark"></span>
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className='col-12 col-md-5 mb-5 mt-0 mt-sm-2'>
                                                                            <div className='row'>
                                                                                <div className='col-12 mb-4 mt-md-3'>
                                                                                    <h5 className='col-12 mb-4'>آیا نیاز
                                                                                        به خدمات طراحی لوگو دارید؟</h5>
                                                                                    <div className='col-12 d-flex'>
                                                                                        <input type="radio"
                                                                                               className='step-2-input step-2-input-bool'
                                                                                               checked={this.state.has_logo == 1}
                                                                                               onChange={this.hasLogo}
                                                                                               id="Yes1"
                                                                                               name="has_logo_yes"
                                                                                               value="1"/>
                                                                                        <label htmlFor="Yes1">
                                                                                            <div
                                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                                <h5 className='mb-0'>بله</h5>
                                                                                            </div>
                                                                                        </label>
                                                                                        <input type="radio"
                                                                                               className='step-2-input step-2-input-bool'
                                                                                               checked={this.state.has_logo == '0'}
                                                                                               onChange={this.hasLogo}
                                                                                               id="No1"
                                                                                               name="has_logo_no"
                                                                                               value="0"/>
                                                                                        <label htmlFor="No1">
                                                                                            <div
                                                                                                className='dv-card-back d-flex justify-content-center flex-column align-items-center'>
                                                                                                <h5 className='mb-0'>خیر</h5>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <h5 className='col-12 mb-4'>درصورتی که
                                                                                    لوگو
                                                                                    دارید برای ما آپلود کنید.</h5>
                                                                                <div className='col-12 mb-md-5'>
                                                                                    <label
                                                                                        className='position-relative mb-3'>
                                                                                        <input type="file"
                                                                                               onChange={this.UploadFileInput}
                                                                                               accept="image/jpeg, image/png"
                                                                                               id="upload_logo"
                                                                                               className="custom-file-input"/>
                                                                                        <i className="fas fa-paperclip dv-custom-icon"/>
                                                                                    </label>
                                                                                    <img src={this.state.file}
                                                                                         className='img-fluid rounded-lg'/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div> :
                                                                    this.state.activeStep === 7
                                                                        ? <div className='row'>
                                                                            <div
                                                                                className='col-12 col-md-4 mb-5 mt-0 mt-sm-2'>
                                                                                <h5 className='mb-4'>طرح های مورد علاقه
                                                                                    شما</h5>
                                                                                <p className='mb-4 dv-text-justify'>
                                                                                    پروژه هایی که به آن ها علاقه دارید را به
                                                                                    ما
                                                                                    معرفی کنید همچنین دلیل جلب شدن توجه خود
                                                                                    به
                                                                                    این طراحی را برای ما شرح دهید. تا
                                                                                    بتوانید
                                                                                    این نقاط قوت و سلیقه شما را در طرح لحاظ
                                                                                    کنیم.
                                                                                </p>
                                                                                <div className='mb-4'>
                                                                                    <input
                                                                                        type={'text'}
                                                                                        name={'url'}
                                                                                        onChange={this.siteUrls}
                                                                                        value={this.state.urls}
                                                                                        placeholder="آدرس اینترنتی یا نام اپلیکیشن"
                                                                                        className='rj-form ltr'/>
                                                                                </div>
                                                                                <div
                                                                                    className='mb-4 dv-textarea-parent dv-textarea-2'>
                                                                                            <textarea
                                                                                                onChange={this.siteUrlsDescription}
                                                                                                value={this.state.url_description}
                                                                                                className='dv-textarea p-3'
                                                                                                rows={5}
                                                                                                placeholder="توضیحات"/>
                                                                                </div>
                                                                                <div
                                                                                    className='d-flex justify-content-center align-items-center'>
                                                                                    <button className='dv-custom-btn'
                                                                                            onClick={this.saveUrls}>ثبت
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-8 mb-3'>
                                                                                <div className='row mt-4'>
                                                                                    {
                                                                                        url_list.map((row, index) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className='col-12 col-md-6'
                                                                                                        key={index}>
                                                                                                        <div
                                                                                                            className='d-flex justify-content-between align-items-center bg-link-parent mb-4'>
                                                                                                            <div
                                                                                                                className='d-flex dv-left-icons'>
                                                                                                                <i className="far fa-pen"
                                                                                                                   onClick={() => this.handleShow(index)}/>
                                                                                                                <i className="fal fa-times"
                                                                                                                   onClick={() => this.removeUrl(index)}/>
                                                                                                            </div>
                                                                                                            <span
                                                                                                                className='dv-link ltr'>{row.url}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div> :
                                                                        ''
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12 ltr'>
                                        {/*Next And Prev Button*/}
                                        <div
                                            style={{marginTop: '1rem'}}
                                            className='d-flex justify-content-center align-items-center mb-5 mb-md-2'>
                                            <div
                                                className={this.state.activeStep === steps.length ? 'dv-next-btn-last d-flex justify-content-center align-items-center rounded-circle' : 'dv-next-btn d-flex justify-content-center align-items-center rounded-circle'}
                                                onClick={this.handleOnClickNext}><i className="fas fa-chevron-left"></i>
                                            </div>
                                            <div className='d-flex align-items-center'><span
                                                className=' rounded-lg'>{steps.length} </span><span
                                                className='m-1'>/</span><span
                                                className='dv-number-of-step'> {this.state.activeStep} </span>
                                            </div>
                                            <div
                                                className={this.state.activeStep === steps.length - (steps.length - 1) ? 'dv-prev-btn-first d-flex justify-content-center align-items-center rounded-circle' : 'dv-prev-btn d-flex justify-content-center align-items-center rounded-circle'}
                                                onClick={this.state.activeStep === steps.length ? null : this.handleOnClickBack}>
                                                <i className="fas fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </>

                        }
                        <Modal className='p-4' show={this.state.show} onHide={this.handleClose}>
                            <h4 className='text-center pb-4'>{this.state.select_url}</h4>
                            <Modal.Body>
                                            <textarea className='w-100 rounded-lg dv-color-textarea' rows={5}
                                                      value={this.state.select_description}
                                                      onChange={this.editTextDescription}>
                                                {this.state.select_description}
                                            </textarea>
                            </Modal.Body>
                            <div className='pt-4 d-flex justify-content-center align-items-center'
                                 style={{textAlign: 'center'}}>
                                <button className='dv-btn-close' onClick={this.handleClose}>انصراف</button>
                                <button className='dv-btn-save2' onClick={this.saveDescript}>ذخیره</button>
                            </div>
                        </Modal>
                    </div>
                    {
                        this.state.isUsersLoader ?
                            <Loader show/> : ''
                    }
                </div>
            </div>
        )
    }
}