import React, {Component} from 'react';
import Aral from "../../assets/images-svg/Logo.png";
import '../../assets/styles/ltr-pages.css';
import "mdbreact/dist/css/mdb.css";
import {MDBInput} from "mdbreact";
import {getData} from "../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../assets/scripts/GeneralVariables";
import {toast} from "react-toastify";
import Loader from "./Loader/Loader";

class Welcome extends Component {

    constructor() {
        super();
        this.state = {
            phone_number : '',isUsersLoader : false
        }
    }

    phoneNumberInput = (e) => {
        this.setState({
            phone_number: e.target.value
        })

    };

    loginHandler = async (e) => {
        e.preventDefault()
        let numRegex = /[0-9]{11}|[۰-۹]{11}/;
        if (this.state.phone_number === "" || !numRegex.test(this.state.phone_number)) {
            toast.error("شماره موبایل نامعتبر");
            return false;
        }else {
            this.setState({isUsersLoader : true})
            let data = await getData(MAIN_URL, 'login', 'post', {mobile : this.state.phone_number}, false, true);
            if (data) {
                if(data.status === 200){
                    toast.success('باموفقیت وارد شدید')
                    localStorage.setItem('mobile' , this.state.phone_number)
                    localStorage.setItem('token' , data.token);
                    window.location.replace('/form')
                    this.setState({isUsersLoader : false})
                }
            }
        }
    }

    render() {

        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12 offset-md-2 col-md-8'>
                        <form onSubmit={this.loginHandler} className='d-flex justify-content-center flex-column align-items-center dv-h-130'>
                            {/*Set Logo*/}
                            <img src={Aral} className='img-fluid dv-aral-logo mt-1 mb-4' alt='Aral'/>
                            {/*End Set Logo*/}

                            {/*Set Text*/}
                            <h1 className='mb-5 title-color rtl'>به فرم نیازسنجی شرکت راهکارگستران خوش آمدید</h1>

                            <p className='title-color mb-2 rtl text-right'>
                                برای درک بهتر از آنچه برای وبسایت/اپلیکیشن خود نیاز دارید، لطفا کمتر از 10 دقیقه از زمان خود را در اختیار ما قرار دهید و فرم پرسشنامه اولیه <a href='https://rahkargostaran.ir'>شرکت راهکارگستران</a> را با دقت تکمیل نمایید.
                            </p>
                            <p className='title-color mb-2 rtl text-right w-100'>
                                اطلاعات شما نزد ما محفوظ خواهد ماند.
                            </p>
                            <p className='title-color mb-2 rtl text-right w-100'>
                                کارشناسان ما به زودی با شما تماس خواهند گرفت، از همکاری شما صمیمانه سپاسگذاریم.
                            </p>

                            <div className='mt-3 mb-1 dv-width-input-welcome-parent'>
                                <MDBInput onChange={this.phoneNumberInput}
                                          value={this.state.phone_number} label="شماره تماس"
                                          type="number" name={"mobile"}

                                          className='rj-form dv-width-input-welcome ltr'/>
                            </div>

                            <button type={'submit'} className='mt-4 mb-2 dv-flex-btn'>ورود</button>

                        </form>
                    </div>
                </div>
                {
                    this.state.isUsersLoader ?
                        <Loader show /> : ''
                }
            </div>
        );

    }

}

export default Welcome;