import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/bootstrap.min.css';
import './assets/styles/fonts.css';
import './assets/styles/styles.css';
import './assets/styles/fontawesome-all.css'
import App from './App';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <>
        <BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <App/>
        </BrowserRouter>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
